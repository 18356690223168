<template>
  <div id="div_SK" align="center">
		<h2><span class="myspan">Froggie stránky s doma vyrobenými šitími<br/>a vyšívanými textilnými produktami ako sú tašky a iné veci<br/>v našej malej továrni so šijacím strojom Bernina.</span></h2>
		<h3><span class="myspan">Ponúkame veľa produktov ako:</span></h3>
		<h4><span class="myspan">taška</span></h4><h4><span class="myspan">kufrík</span></h4><h4><span class="myspan">taštička</span></h4><h4><span class="myspan">chňapka</span></h4><h4><span class="myspan">peračník</span></h4><h4><span class="myspan">košík</span></h4><h4><span class="myspan">batoh</span></h4><h4><span class="myspan">a veľa ďalších...</span></h4>
    <div>
      <h1><span class="myspansmall">Darčekové šitie</span></h1>
      <h2><span class="myspansmall">Naše produkty</span></h2>
      <h3><span class="myspansmall">Látky</span></h3>
      <h4><span class="myspansmall">Používame overené látky nakúpené v rôznych obchodoch a s Vašou konzultáciou.</span></h4>
      <h3><span class="myspansmall">Šitie</span></h3>
      <h4><span class="myspansmall">Šijeme so strojom Bernina Bernetta a nite spolu s ostatnými voliteľnými časťami výrobku sú pred samotnou tvorbou volené Vami.</span></h4>
    <router-link
      :to="{ name: 'Index', params: {} }"
      class="button is-info is-small mylink"
      >Hlavná stránka</router-link
    >
    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    </div>
	</div>
</template>

<script>
export default {
  name: 'Froggie Boutique',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
