<template>
  <div>
    <div class="field">
      <label class="label">Kód</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="Code"
          v-model="code"
        />
      </div>
    </div>

    <div class="field">
      <label class="label">Popis</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="Description"
          v-model="description"
        />
      </div>
    </div>
  
    <div class="field">
      <label class="label">Obrázok</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="Picture"
          v-model="picture"
        />
      </div>
    </div>
  
    <div class="field">
      <label class="label">Meno obrázku</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="Picture Name"
          v-model="picturename"
        />
      </div>
    </div>
  
    <div class="field">
      <label class="label">Cena</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="Price"
          v-model="price"
        />
      </div>
    </div>

    <div class="field">
      <label class="label">Id skupiny</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="Group Id"
          v-model="group_id"
        />
      </div>
    </div>

    <div class="field">
      <label class="label">Dodatočná poznámka</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="Additional Notes"
          v-model="additional_notes"
        />
      </div>
    </div>

    <div class="control">
      <a class="button is-success mylink" style="cursor: pointer;" @click="saveProduct">Uložiť</a>
    </div>

    <div class="control">
      <br/><br/><br/>
      <router-link
      :to="{ name: 'Index', params: {} }"
      class="button is-info is-small mylink">Hlavná stránka</router-link>
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    </div>

  </div>
</template>
  
<script>
// import axios
import axios from "axios";
import authHeader from '../auth-header';
  
export default {
  name: "AddProduct",
  data() {
    return {
      code: "",
      description: "",
      picture: "",
      picturename: "",
      price: "",
      group_id: "",
      additional_notes: ""
    };
  },
  methods: {
    // Create New product
    async saveProduct() {
      try {
        await axios.post("/data/api/products", {
          code: this.code,
          description: this.description,
          picture: this.picture,
          picturename: this.picturename,
          price: this.price,
          group_id: this.group_id,
          additional_notes: this.additional_notes
        }, { headers: authHeader() });
        this.code = "";
        this.description = "";
        this.picture = "";
        this.picturename = "";
        this.price = "";
        this.group_id = "";
        this.additional_notes = "";
        this.$router.push("/");
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
  
<style>
</style>