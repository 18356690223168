<template>
  <div id="app" class="container is-max-desktop">
    <div id="infopart">
      <h1><span class="myspan">FROGGIE BOUTIQUE</span></h1>
      <h2><span class="myspansmall">Ručne šité veci pre praktické využitie.</span></h2>
      <h3><span class="myspansmall">Viaceré farby alebo látky podľa ponuky nie sú pre nás problém.</span></h3>
    </div>
    <router-view />
  </div>
</template>
  
<script>
//Bootstrap and jQuery libraries
//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'jquery/dist/jquery.min.js';

export default {
  name: "App",
};
</script>
  
<style>
</style>
