<template>
  <div>
    <div class="field">
      <label class="label">Kód</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="Kód"
          v-model="code"
          readonly
        />
      </div>
    </div>

    <div class="field">
      <label class="label">Popis</label>
      <div class="control">
        <textarea
          class="textarea"
          type="text"
          placeholder="Popis"
          v-model="description"
          rows="3" cols="20"
          readonly
        />
      </div>
    </div>

    <div class="field">
      <label class="label">Obrázok</label>
      <div class="control" v-if="picture.trim().replace(' ', '').split('|').length > 1">
        <router-link v-for="itemPictureDetail in picture.trim().replace(' ', '').split('|')" :key="itemPictureDetail" :to="'/resources/pictures/products/' + itemPictureDetail" target="_blank" class="thumbnail">
          <img :alt="picturename" :src="'/resources/pictures/products/small_' + itemPictureDetail" height="100px" />
          <span><img :src="'/resources/pictures/products/small_' + itemPictureDetail" /><br/>{{ picturename }}</span>
        </router-link>
      </div>
      <div class="control" v-if="picture.trim().replace(' ', '').split('|').length === 1">
        <router-link :to="'/resources/pictures/products/' + picture" target="_blank" class="thumbnail">
          <img :alt="picturename" :src="'/resources/pictures/products/small_' + picture" height="100px" />
          <span><img :src="'/resources/pictures/products/small_' + picture" /><br/>{{ picturename }}</span>
        </router-link>
      </div>
    </div>
  
    <div class="field">
      <label class="label">Meno obrázku</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="Meno obrázku"
          v-model="picturename"
          readonly
        />
      </div>
    </div>

    <div class="field">
      <label class="label">Cena</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="Cena"
          v-model="price"
          readonly
        />
      </div>
    </div>

    <!--
    <div class="field">
      <label class="label">Id skupiny</label>
      <div class="control">
        <input
          class="input"
          type="text"
          placeholder="Group Id"
          v-model="group_id"
          readonly
        />
      </div>
    </div>
    -->

    <div class="field">
      <label class="label">Dodatočná poznámka</label>
      <div class="control">
        <textarea
          class="textarea"
          type="text"
          placeholder="Dodatočná poznámka"
          v-model="additional_notes"
          rows="3" cols="20"
          readonly
        />
      </div>
    </div>
    
    <br/><br/><br/>
    <div class="control">
      <router-link :to="{ name: 'Index', params: {} }" class="button is-info is-small mylink">Hlavná stránka</router-link>
    </div>
    <br/><br/><br/><br/><br/><br/>
  </div>
</template>
  
<script>
// import axios
import axios from "axios";
import authHeader from '../auth-header';
  
export default {
  name: "DetailProduct",
  data() {
    return {
      code: "",
      description: "",
      picture: "",
      picturename: "",
      price: "",
      group_id: "",
      additional_notes: ""
    };
  },
  created: function () {
    this.getProductById();
  },
  methods: {
    // Get Product By Id
    async getProductById() {
      try {
        const response = await axios.get(
          `/data/api/products/${this.$route.params.id}`
        );
        this.code = response.data.code;
        this.description = response.data.description;
        this.picture = response.data.picture;
        this.picturename = response.data.picturename;
        this.price = response.data.price;
        this.group_id = response.data.group_id;
        this.additional_notes = response.data.additional_notes;
      } catch (err) {
        console.log(err);
      }
    },
  
    // Update product
    async updateProduct() {
      try {
        await axios.put(
          `/data/api/products/${this.$route.params.id}`,
          {
          code: this.code,
          description: this.description,
          picture: this.picture,
          picturename: this.picturename,
          price: this.price,
          group_id: this.group_id,
          additional_notes: this.additional_notes
        }, { headers: authHeader() });
        this.code = "";
        this.description = "";
        this.picture = "";
        this.picturename = "";
        this.price = "";
        this.group_id = "";
        this.additional_notes = "";
        this.$router.push("/");
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
  
<style>
</style>