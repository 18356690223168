<template>
  <div ref="beginOfPage">
    <div class="control basketdiv">
      <a
              class="button is-info is-small mylink" style="cursor: pointer;"
              @click="emptyBasket()"
              >Vyprázdniť košík</a>
      <div class="has-text-centered" style="display: flex; justify-content: center; align-items: center;">
        <span class="myspan">Košík&nbsp;{{ textInBasket }}</span>
        <ul style="list-style-type: none; margin: 0; padding: 0; overflow: hidden;">
          <li v-for="item in items" :key="item.product_id" style="float: left;">
            <span v-if="item.checked" class="myspansmall" v-bind:title="item.description" :alt="item.description">{{ item.code }}&nbsp;
              <a v-for="itemPicture in item.picture.split('|')" :key="itemPicture" class="thumbnailsmall">
                <img :alt="item.picturename" :src="'/resources/pictures/products/small_' + itemPicture" height="50px" />
                <span>{{ item.picturename }}</span>
              </a>
              &nbsp;
              <input
                class="input"
                type="text"
                placeholder="1"
                v-model="item.amount"
                size="2" width="2" style="width: 20%;"
                maxlength = "2" min = "1" max = "99"
              />
              &nbsp;
            </span>
          </li>
        </ul>
      </div>
      <div class="control" ref="email">
        <input
          class="input"
          type="text"
          placeholder="email"
          v-model="email"
          size="5" width="5" style="width: 40%;"
          maxlength = "100"
        /><span class="myspansmall">{{ emailSent }}</span>
        <a
                class="button is-info is-small mylink" style="cursor: pointer;"
                @click="orderBasket()"
                >Objednať z košíka</a>
      </div>
    </div>
    <div class="control" v-if="showAdminBoard"><router-link :to="{ name: 'Create' }" class="button is-success mt-5">Pridaj nový</router-link></div>
    <table class="table is-striped is-bordered mt-2 is-fullwidth">
      <thead>
        <tr>
          <th>#</th>
          <th>Kód</th>
          <th>Popis</th>
          <th>Obrázok</th>
          <th>Cena</th>
          <th class="has-text-centered" v-if="showAdminBoard">Akcie</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.product_id" :class="{'even': item.product_id % 2 === 0, 'odd': item.product_id % 2 !== 0 }">
          <td><input type="checkbox" v-model="item.checked" class="largerCheckbox" v-on:change="refreshPage()"/></td>
          <td>{{ item.code }}
            <router-link :to="{ name: 'Detail', params: { id: item.product_id } }" v-bind:title="item.additional_notes" class="button is-info is-small generallink">
              <i class="fa fa-search-plus" style="font-size:24px"></i>
            </router-link>
          </td>
          <td>{{ item.description }}</td>
          <td>
              <router-link v-for="itemPicture in item.picture.split('|')" :key="itemPicture" :to="'/resources/pictures/products/' + itemPicture" target="_blank" class="thumbnail">
                <img :alt="item.picturename" :src="'/resources/pictures/products/small_' + itemPicture" height="100px" />
                <span><img :src="'/resources/pictures/products/small_' + itemPicture" /><br/>{{ item.picturename }}</span>
              </router-link>
          </td>
          <td>{{ item.price }}</td>
          <td class="has-text-centered" v-if="showAdminBoard">
            <router-link
              :to="{ name: 'Edit', params: { id: item.product_id } }"
              class="button is-info is-small"
              >Editovať</router-link
            >
            &nbsp;
            <a
              class="button is-danger is-small" style="cursor: pointer;"
              @click="deleteProduct(item.product_id)"
              >Zmazať</a
            >
          </td>
        </tr>
      </tbody>
    </table>
    <br/><br/><br/>
    <router-link
      :to="{ name: 'About', params: {} }"
      class="button is-info is-small mylink"
      >O nás</router-link>
    <br/><br/><br/>
    <nav>
    <div v-if="!currentUser" class="control">
        <router-link to="/login" class="button is-info is-small mylink">Prihlásenie</router-link>
    </div>
    <div v-if="currentUser" class="control">
        <div class="control" v-if="showAdminBoard">
          <router-link :to="{ name: 'Register', params: {} }" class="button is-info is-small mylink">Registrácia</router-link><br/>
          <router-link :to="{ name: 'UserList', params: {} }" class="button is-info is-small mylink">Zoznam používateľov</router-link>
        </div>
        &nbsp;&nbsp;&nbsp;
        <router-link to="/profile" class="button is-info is-small mylink">
          {{ currentUser.username }}
        </router-link>
        &nbsp;&nbsp;&nbsp;
        <a class="button is-info is-small mylink" href @click.prevent="logOut">Odhlásenie</a>
    </div>
    </nav>
    <br/><br/><br/>
    <br/><br/><br/>
    <br/><br/><br/>
  </div>
</template>
  
<script>
// import axios
import axios from "axios";
import authHeader from '../auth-header';

export default {
  name: "ProductList",
  data() {
    return {
      items: [],
      textInBasket: "prázdny",
      email: "",
      emailSent: "",
      clientIP: "neznáma IP",
      serverUrlPrefix: "",
    };
  },

  created() {
    this.getProducts();

    try {
      fetch('https://api.ipify.org?format=json')
      .then(x => x.json())
      .then(({ ip }) => {
          this.clientIP = "IP adresa: " +  ip;
      });

      // Usage!
      this.sleep(2000).then(() => {
        if (navigator.geolocation) {
          console.log("Get geolocation...");
          navigator.geolocation.getCurrentPosition(this.showLocation);
          console.log("Get geolocation done.");
        }
      });

    } catch (err) {
      console.log(err);
    }
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR');
      }

      return false;
    }
  },

  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
    
    showLocation(position) {
      try {
        var latitude = position.coords.latitude;
        var longitude = position.coords.longitude;
        this.clientIP += " poloha: Zemepisná šírka: " + latitude + " Zemepisná dĺžka: " + longitude;
        console.log("Data of geolocation: " + this.clientIP);
      } catch (err) {
        console.log(err);
      }
    },

    // sleep time expects milliseconds
    sleep (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },

    // Empty basket
    async emptyBasket() {
      try {
        //this.getProducts();
        var newLength = this.items.length;
        var newItems = this.items;
        for(var itemIndex = 0; itemIndex < newLength; itemIndex++) {
          newItems[itemIndex].checked = false;
          newItems[itemIndex].amount = null;
        }
        this.items = newItems;
        this.emailSent = "";
        this.email = "";
        this.textInBasket = "prázdny";

        this.$forceUpdate();
      } catch (err) {
        console.log(err);
      }
    },

    // Order basket
    async orderBasket() {
      try {
        if(!this.ValidateEmail(this.email)) {
          return;
        }

        var orderItems = [];
        var orderIndex = 0;
        var amountsOK = "";
        for(var itemIndex = 0; itemIndex < this.items.length; itemIndex++) {
          if(this.items[itemIndex].checked === true) {
            orderItems[orderIndex] = this.items[itemIndex];
            if(!(orderItems[orderIndex].amount > 0)) amountsOK += orderItems[orderIndex].code + " ";
            orderIndex++;
          }
        }

        if(orderIndex === 0) {
          this.emailSent = ' Objednávka je prázdna! ';
          return;
        }

        if(amountsOK.length > 0) {
          this.emailSent = ' Počet je prázdny pre tieto kódy ' + amountsOK + ' ';
          return;
        }

        // prepare orders for mail
        var mailBody = "";
        mailBody += "<!DOCTYPE html>" + 
          "<html>" +
          "  <head>" +
          "      <meta name=\"viewport\" content=\"width=device-width\">" +
          "      <meta http-equiv=\"Content-Type\" content=\"text/html; charset=UTF-8\">" +
          "      <title>Objednávka Froggie Boutique from " + this.clientIP + "</title>" +
          "  </head>" +
          "  <body>" +
          "  <p style=\"font-size: 14px; font-weight: normal;\">Objednávka pre " + this.email + " (" + this.clientIP + ")</p>" +
          "  <table border=\"1\">" +
          "<tr><th>Kód</th><th>Popis</th><th>Počet</td></tr>";
        for(var orderItemIndex = 0; orderItemIndex < orderItems.length; orderItemIndex++) {
          mailBody += "<tr><td>" + orderItems[orderItemIndex].code + "</td><td>" + orderItems[orderItemIndex].description + "</td><td>" + orderItems[orderItemIndex].amount + "</td></tr>";
        }
        mailBody += 
          "  </table>" +
          "  </body>" +
          "</html>";

        // send mail
        console.log(mailBody);

        this.emailSent = " Email poslaný ";

        //this.getProducts();
        
        try {
          var actualEmail = this.email;

          this.email = "";

          for(itemIndex = 0; itemIndex < this.items.length; itemIndex++) {
            this.items[itemIndex].checked = false;
            this.items[itemIndex].amount = null;
          }

          this.textInBasket = "prázdny";

          // log email
          console.log(actualEmail + " to be sent...");

          const waitTime = 120000;
          const handleError = error => {
              // this makes sure that the FAIL output isn't repeated in the case when there's a failure before the timeout
              if (!error.handled) {
                  if (error.timedout) {
                      console.log("TIMEDOUT", error.timedout);
                  } else {
                      console.log("FAIL!", error.message);
                      error.handled = true;
                      throw error;
                  }
              }
          };

          const myRequest = await axios.post(this.serverUrlPrefix + "/data/api/products/sendemail", {
            email: actualEmail,
            body: mailBody
          }).then(result => {
            console.log("SUCCESS!", JSON.stringify(result.data, null, 2));
          }).catch(handleError);

          const timer = new Promise((_, reject) => setTimeout(reject, waitTime, {timedout: "request taking a long time"}));
          
          await Promise.race([myRequest, timer]).catch(handleError);

          console.log("After send email.");
        } catch (err) {
          console.log(err);
        }
      } catch (err) {
        console.log(err);
      }
    },

    // Get All Products
    async getProducts() {
      try {
        const response = await axios.get(this.serverUrlPrefix + "/data/api/products");
        this.items = response.data;
      } catch (err) {
        console.log(err);
      }
    },
  
    // Delete Product
    async deleteProduct(id) {
      try {
        await axios.delete(this.serverUrlPrefix + `/data/api/products/${id}`, { headers: authHeader() });
        this.getProducts();
      } catch (err) {
        console.log(err);
      }
    },

    async prepareCheckedSomethingText() {
      var predicate = false;

      for(var itemIndex = 0; itemIndex < this.items.length; itemIndex++) {
        if(this.items[itemIndex].checked === true) {
          predicate = true;
          break;
        }
      }

      if(!predicate) {
        this.textInBasket = "prázdny";
      }
      else {
        this.textInBasket = "";
      }
    },

    async scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);

      this.prepareCheckedSomethingText();
    },

    ValidateEmail(inputText) {
      var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if(inputText.match(mailformat)) {
        return true;
      }
      else {
        this.emailSent = " Zadali ste nevalídnu emailovú adresu! ";
        return false;
      }
    },

    async refreshPage() {
      this.prepareCheckedSomethingText();
      this.$forceUpdate();
    }
  },
};
</script>
  
<style>
</style>