<template>
  <div ref="beginOfPage">
    <table class="table is-striped is-bordered mt-2 is-fullwidth">
      <thead>
        <tr>
          <th>Id</th>
          <th>Meno</th>
          <th>Email</th>
          <th>Heslo</th>
          <th>Vytvorené</th>
          <th>Zmenené</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.id" :class="{'even': item.id % 2 === 0, 'odd': item.id % 2 !== 0 }">
          <td>{{ item.id }}</td>
          <td>{{ item.username }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.password }}</td>
          <td>{{ item.createdAt }}</td>
          <td>{{ item.updatedAt }}</td>
        </tr>
      </tbody>
    </table>
    <br/><br/><br/>
    <router-link
      :to="{ name: 'Index', params: {} }"
      class="button is-info is-small mylink"
      >Hlavná stránka</router-link>
    <br/><br/><br/>
    <br/><br/><br/>
    <br/><br/><br/>
  </div>
</template>
  
<script>
// import axios
import axios from "axios";
import authHeader from '../auth-header';

export default {
  name: "UserList",
  data() {
    return {
      items: [],
      serverUrlPrefix: "",
    };
  },

  created() {
    this.getUsers();
  },

  methods: {
    // Get All Users
    async getUsers() {
      try {
        const response = await axios.get(this.serverUrlPrefix + "/data/api/users", { headers: authHeader() });
        this.items = response.data;
      } catch (err) {
        console.log(err);
      }
    },
  
  },
};
</script>
  
<style>
</style>