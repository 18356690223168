import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
//import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import store from './store';
import VeeValidate from 'vee-validate'

import App from './App.vue'
import Create from './components/AddProduct.vue'
import Edit from './components/EditProduct.vue'
import Index from './components/ProductList.vue'
import About from './components/About.vue'
import Detail from './components/ProductDetail.vue'
import Login from './components/Login.vue';
import Register from './components/Register.vue';
import UserIndex from './components/UserList.vue';

import { auth } from './store/auth.module';

//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
//Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
//Vue.use(IconsPlugin)

Vue.use(VeeValidate)
Vue.use(VueRouter)

Vue.use(Vuex)

Vue.config.productionTip = false
  
const routes = [
  {
    name: 'Create',
    path: '/create',
    component: Create
  },
  {
    name: 'Edit',
    path: '/edit/:id',
    component: Edit
  },
  {
    name: 'Index',
    path: '/',
    component: Index
  },
  {
    name: 'About',
    path: '/about',
    component: About
  },
  {
    name: 'Detail',
    path: '/detail',
    component: Detail
  },
  {
    name: 'Login',
    path: '/login',
    component: Login
  },
  {
    name: 'Register',
    path: '/register',
    component: Register
  },
  {
    name: 'UserList',
    path: '/users',
    component: UserIndex
  },
  {
    path: '/profile',
    name: 'Profile',
    // lazy-loaded
    component: () => import('./components/Profile.vue')
  },
];
  
const router = new VueRouter({ mode: 'history', routes: routes })
  
router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/about', '/detail', '/'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

export default new Vuex.Store({
  modules: {
    auth
  }
});
